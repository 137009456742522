import { AppProviders } from "app-providers"
import styled, { createGlobalStyle } from "styled-components"
import { fullSizeCss } from "style/css"
import { ThemeProps } from "style/theme"
import ReactDOM from "react-dom"
import React from "react"
import { App } from "app"

import PWAPrompt from "react-ios-pwa-prompt"
import { install } from "resize-observer"
import * as serviceWorker from "./serviceWorker"

if (document.cookie.indexOf("beta=true") >= 0) {
//  window.location.href = "https://beta.mattflix.watch"
}

if (!window.ResizeObserver) install()
serviceWorker.register()

export const APP_ROOT_ID = "root"

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    vertical-align: baseline;
    background: transparent;
    font-size: 1em;
    box-sizing: border-box;
    font-family: "Helvetica";
  }

  svg {
    color: currentColor;
    fill: currentColor;

    * {
      color: currentColor;
    }
  }

  html {
    font-size: 12pt;
    -ms-overflow-style: none;
  }

  html, body, #${APP_ROOT_ID} {
    height: 100%;
    background: black;
  }

  ::-webkit-scrollbar {
    background: transparent;
  }
`

const AppBackground = styled.div`
  ${fullSizeCss};
  position: fixed;
  background: ${({ theme }: ThemeProps): string => theme.surface.color};
  z-index: 0;
`

ReactDOM.render(
  <>
    <AppProviders>
      <App />
      <AppBackground />
    </AppProviders>
    <GlobalStyle />
    <PWAPrompt timesToShow={3} copyBody="This website has app functionality. Add it to your home screen to use it in fullscreen" />
  </>,
  document.getElementById(APP_ROOT_ID) as HTMLElement,
)
